.contact {
  display: flex;
  height: 100vh;
  align-items: center;
  padding: 5% 10% 0 10%;
  background: #212121;
  color: white;
  gap: 5%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.footer-left {
  width: 40%;
  display: flex;
}

.footer-left h1 {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: url('https://t3.ftcdn.net/jpg/05/58/61/32/360_F_558613274_Z1zbjnHZKjpnTvvsjfZzYXk2TIeUl54a.jpg');
  font-size: 7rem;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 0.2rem;
}

.footer-right {
  width: 60%;
}

.footer-right h3 {
  font-size: 2.2rem;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 0.2rem;
  opacity: 0.8;
}

.interests {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.interests p {
  font-size: 1.5rem;
  padding: 0.4rem 1rem;
  border-radius: 100px;
  border: 1px solid black;
  font-family: 'Orbit', sans-serif;
}

hr {
  width: 100%;
  margin: 3% 0;
  border-bottom: 0.5px rgba(32, 32, 32, 0.247);
}

h3 span {
  letter-spacing: 0;
  color: white;
}

h3 span a {
  font-family: 'Indie Flower', sans-serif;
  position: relative;
}

h3 span a::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 7px;
  width: 100%;
  border: solid 2px;
  border-color: #4fb7ff transparent transparent transparent;
  border-radius: 30%;
}

.social {
  display: flex;
  align-items: center;
  gap: 2rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min-resolution: 125dpi) {
  .footer-left h1 {
    font-size: 7rem;
  }

  .footer-right h3 {
    font-size: 2rem;
  }

  .interests p {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }
}

/* for mobile and tablet devices */
@media only screen and (max-width: 768px) {

  .contact {
    flex-direction: column;
    padding: 5%;
    height: auto !important;
  }

  .footer-right, .footer-left {
    width: 100%;
  }

  .footer-left{
    visibility: hidden;
    height: 0;
  }

  .footer-left h1 {
     font-size: 5rem;
  }

  .footer-right h3 {
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
  }

  .interests {
    gap: 0.5rem;
  }

  .interests p {
    font-size: 0.9rem;
    margin: 0.5rem 0;
  }

  .social {
    flex-wrap: wrap;
  }

  h3 span a::after {
    bottom: -5px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1920px) {
  .about-skills {
    width: 80%;
  }

  .footer-right h3 {
    font-size: 1.5rem;
  }

  .footer-left {
    width: 60%;
  }

  .interests p {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
}